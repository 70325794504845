<template>
  <fw-layout
    full
    management
    :back-to="`/manage/vacations/${map.year}/maps`"
    :loading="initialLoading"
    loading-title="Mapa de equipa"
    loading-icon="group"
    right-sidebar-width="w-72 min-w-72"
  >
    <template #header-nav>
      <div class="flex flex-col max-w-xl">
        <v-clamp class="font-semibold text-base" autoresize :max-lines="1">{{ map.title }}</v-clamp>
        <fw-label class="hidden md:flex" marginless>Mapa de férias {{ map.year }}</fw-label>
      </div>
    </template>

    <template v-if="map?.key" #main-content>
      <PanelManageMapUsers ref="manageMapUsersList" :map="map" />
    </template>

    <template #right-sidebar>
      <div class="px-5 py-3">
        <div>
          <fw-label>Estado da versão do mapa</fw-label>
          <fw-tag
            size="lg"
            expanded
            :type="statusColors[map.status]"
            custom-class="px-3 flex items-center justify-center gap-2"
          >
            <span>{{ statusLabels[map.status]?.['pt'] }}</span>
          </fw-tag>
          <fw-panel-info
            v-if="map.status === 'closed' && (userPermissions.isManager || userPermissions.isAdmin)"
            size="xs"
            clean
            icon
            class="mt-2"
          >
            O mapa de equipa está indisponível e não pode ser visualizado pelos trabalhadores.
          </fw-panel-info>
          <fw-panel-info v-else-if="map.status === 'open'" size="xs" clean icon class="mt-2">
            O mapa de equipa disponivel está disponível e todos os trabalhadores podem editar os seus mapas individuais
            de férias.
          </fw-panel-info>
        </div>
        <div v-if="canOpenMap || canApproveSubmitted" class="text-sm border-t py-2 mt-3 text-gray-600">
          <fw-label>Operações</fw-label>
          <div class="flex flex-col">
            <fw-button v-if="canOpenMap" :type="'link'" icon="arrow-right" @click.native="confirmOpenMap"
              >Disponibilizar mapa</fw-button
            >
            <fw-button v-if="canApproveSubmitted" :type="'link'" icon="arrow-right" @click.native="comfirmApproveMaps"
              >Aprovar mapas submetidos</fw-button
            >
          </div>
        </div>
        <div
          v-if="userPermissions.isManager || userPermissions.isAdmin || userPermissions.isMapsManager"
          class="text-sm border-t py-2 mt-3 text-gray-600"
        >
          <fw-label>Exportar</fw-label>
          <div class="flex flex-col">
            <fw-button
              :type="'link'"
              icon="arrow-right"
              :loading="downloading.pdf"
              :disabled="downloading.pdf"
              @click.native="getManagerMapPDF"
              >Exportar em .PDF</fw-button
            >

            <fw-button
              :type="'link'"
              icon="arrow-right"
              :loading="downloading.csv"
              :disabled="downloading.csv"
              @click.native="getManagerMapCSV"
              >Exportar em .CSV</fw-button
            >
          </div>
        </div>
        <div class="flex flex-col gap-3 text-sm border-t py-2 mt-3 text-gray-600">
          <div>
            <fw-label marginless>Nome do mapa</fw-label>
            <div>{{ map?.title }}</div>
          </div>

          <div v-if="map?.manager_key">
            <fw-label>Responsável</fw-label>
            <Person
              :key="map?.manager_key"
              size="xs"
              :person="users?.[map.manager_key]"
              paddingless
              :name-max-lines="3"
            />
          </div>

          <div v-if="map?.sub_manager_keys?.length">
            <fw-label :counter="map.sub_manager_keys?.length ?? 0">Responsáveis intermédios</fw-label>
            <template v-for="userKey in map?.sub_manager_keys">
              <Person
                :key="userKey"
                size="xs"
                :person="users?.[userKey]"
                paddingless
                :name-max-lines="3"
                class="mb-1.5"
              />
            </template>
          </div>

          <div v-if="map?.manager_creditdays_key">
            <fw-label>Responsável dias de crédito</fw-label>
            <Person size="xs" :person="users?.[map.manager_creditdays_key]" paddingless :name-max-lines="3" />
          </div>

          <div v-if="map?.sub_manager_creditdays_keys?.length">
            <fw-label :counter="map.sub_manager_creditdays_keys.length"
              >Responsáveis intermédios de dias de crédito</fw-label
            >
            <template v-for="userKey in map?.sub_manager_creditdays_keys">
              <Person
                :key="userKey"
                paddingless
                size="xs"
                :person="users?.[userKey]"
                :name-max-lines="3"
                class="mb-1.5"
              />
            </template>
          </div>

          <div v-if="map?.reporter_keys?.length">
            <fw-label :counter="map.reporter_keys.length">Observador</fw-label>
            <template v-for="userKey in map?.reporter_keys">
              <Person
                :key="userKey"
                paddingless
                size="xs"
                :person="users?.[userKey]"
                :name-max-lines="3"
                class="mb-1.5"
              />
            </template>
          </div>

          <div v-if="map?.global_reporter_keys?.length">
            <fw-label :counter="map.global_reporter_keys.length">Observador geral</fw-label>
            <template v-for="userKey in map?.global_reporter_keys">
              <Person
                :key="userKey"
                paddingless
                size="xs"
                :person="users?.[userKey]"
                :name-max-lines="3"
                class="mb-1.5"
              />
            </template>
          </div>
        </div>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageMapUsers from '@/components/panels/manage/PanelManageMapUsers'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import { MAP_STATUS_COLORS, MAP_STATUS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    Person,
    PanelManageMapUsers
  },

  data() {
    return {
      map: {},
      users: {},
      initialLoading: true,
      loading: false,
      statusColors: MAP_STATUS_COLORS,
      statusLabels: MAP_STATUS,
      statusCount: {},
      downloading: {
        pdf: false,
        csv: false
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    mapKey() {
      return this.$route.params.key
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    canApproveSubmitted() {
      return (
        this.map.status !== 'closed' &&
        this.statusCount.submitted > 0 &&
        (this.userPermissions.isManager ||
          this.userPermissions.isAdmin ||
          this.map?.manager_creditdays_key == this.user.key ||
          this.map?.manager_key == this.user.key)
      )
    },

    canOpenMap() {
      return (this.userPermissions.isManager || this.userPermissions.isAdmin) && this.map.status === 'closed'
    }
  },

  watch: {
    mapKey(key) {
      if (key) this.getMap()
    }
  },

  mounted() {
    this.getMap()
  },

  methods: {
    async getMap() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManageMap(this.mapKey)
        //console.log('getManageMap', response)
        this.map = response.map
        this.users = response.users
        this.statusCount = response.status
      })

      this.loading = false
      this.initialLoading = false
    },

    confirmOpenMap() {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        message:
          'Tem a certeza que deseja disponibilizar o mapa? Esta ação irá enviar uma notificação a todos os trabalhadores associados ao mapa.',
        onConfirm: async () => {
          this.openMap()
        },
        confirmText: 'Disponibilizar',
        cancelText: 'Cancelar'
      })
    },

    async openMap() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateManagerMaps({ map_keys: [this.mapKey], status: 'open' })
        //console.log('updateManagerMaps', response)
        this.map = response.maps[0]
        this.users = response.users
      })

      this.loading = false
    },

    comfirmApproveMaps() {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: 'Aprovar mapas submetidos',
        message: `Tem a certeza que deseja aprovar <b>todos</b> os mapas <b>submetidos</b>?`,
        onConfirm: async () => {
          this.approveMaps()
        },
        confirmText: 'Aprovar todos',
        cancelText: 'Cancelar'
      })
    },

    async approveMaps() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.approveManagerMapUsers(this.mapKey)
        if (response.user_maps?.length) {
          this.$buefy.toast.open({
            message: `Aprovados ${response.user_maps?.length} mapas!`,
            type: 'is-success'
          })
          this.$refs.manageMapUsersList.getMaps()
        } else {
          this.$buefy.toast.open({
            message: 'Sem mapas que possam ser aprovados.',
            type: 'is-warning'
          })
        }
        //console.log('approveManagerMapUsers', response)
      })

      this.loading = false
    },

    async getManagerMapPDF() {
      this.downloading.pdf = true
      await utils.tryAndCatch(this, async () => {
        const filename = `${this.map.title}-${this.map.year}.pdf`
        const response = await this.api.getManagerMapPDF(this.mapKey, filename)
        utils.downloadFile(window.URL.createObjectURL(new Blob([response])), filename)
      })
      this.downloading.pdf = false
    },
    async getManagerMapCSV() {
      this.downloading.csv = true
      await utils.tryAndCatch(this, async () => {
        const filename = `${this.map.title}-${this.map.year}.csv`
        const response = await this.api.getManagerMapCSV(this.mapKey, filename)
        utils.downloadFile(window.URL.createObjectURL(new Blob([response])), filename)
      })
      this.downloading.csv = false
    }
  }
}
</script>
